import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import {
	filterModuleStatsDataByYear,
	promotionYearToCurriculum,
} from "../Utils/Utils";

export const useCitiesQuery = (enabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tepitechprogression", "filters_options", "cities"],
		queryFn: () => scraper.cities.getCities(),
		enabled,
		select: (data) =>
			data.map((current) => ({
				label: current.name,
				value: current.code,
			})),
	});
};

export const useTepitechGradesStats = (areRequestEnabled) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["tepitechprogression", "grades_stats"],
		queryFn: () => scraper.module.getGradesStats("G-ENG-001"),
		select: (data) => filterModuleStatsDataByYear(data.data),
		enabled: areRequestEnabled,
	});
};

const calcuteSuccessPercentage = ({ succeeded, total }) => {
	if (succeeded === 0) return 0;
	return (succeeded * 100) / total;
};

export const useTepitechProgressionsStats = (
	cities,
	years,
	curriculums,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"tepitech_progression_stats",
			...cities,
			...years,
			...curriculums,
		],
		queryFn: () =>
			scraper.english.getTepitechProgressions(cities, years, curriculums),
		enabled: areRequestEnabled,
		select: (data) => {
			const response = data.data;
			return Object.keys(response).reduce((acc, year) => {
				Object.keys(response[year]).forEach((field) => {
					Object.keys(response[year][field]).forEach((city_code) => {
						Object.keys(response[year][field][city_code]).forEach(
							(promotionYear) => {
								const promotion = promotionYearToCurriculum(
									promotionYear,
									year
								);
								Object.keys(
									response[year][field][city_code][
										promotionYear
									]
								).forEach((date) => {
									const key = `${city_code} - ${promotion} - ${year}`;
									if (!(key in acc)) acc[key] = [];
									const percentage = calcuteSuccessPercentage(
										response[year][field][city_code][
											promotionYear
										][date]
									);
									acc[key].push({
										date,
										value: percentage.toFixed(2),
									});
								});
							}
						);
					});
				});
				return acc;
			}, {});
		},
	});
};
