export default class Referentials {
	constructor(api) {
		this.api = api;
	}

	getReferential() {
		return this.api.processRequest("GET", "/referentials");
	}

	updateReferentialName(id, name) {
		return this.api.processRequest("PUT", `/referentials/${id}`, {
			data: {
				title: name,
			},
		});
	}

	createReferential(name) {
		return this.api.processRequest("POST", "/referentials", {
			data: {
				title: name,
			},
		});
	}

	deleteReferential(id) {
		return this.api.processRequest("DELETE", `/referentials/${id}`);
	}

	getReferentialBlocks(referentialId) {
		return this.api.processRequest("GET", `/referentials/${referentialId}`);
	}

	createReferentialBlock(referentialId, blockTitle) {
		return this.api.processRequest(
			"POST",
			`/referentials/${referentialId}/blocks`,
			{
				data: {
					title: blockTitle,
				},
			}
		);
	}

	updateReferentialBlock(blockId, blockTitle) {
		return this.api.processRequest(
			"PUT",
			`/referentials/blocks/${blockId}`,
			{
				data: {
					title: blockTitle,
				},
			}
		);
	}

	deleteReferentialBlock(blockId) {
		return this.api.processRequest(
			"DELETE",
			`/referentials/blocks/${blockId}`
		);
	}

	createReferentialBlockSkill(blockId, skillTitle) {
		return this.api.processRequest(
			"POST",
			`/referentials/blocks/${blockId}/skills`,
			{
				data: {
					title: skillTitle,
				},
			}
		);
	}

	updateReferentialBlockSkill(skillId, skillTitle) {
		return this.api.processRequest(
			"PUT",
			`/referentials/skills/${skillId}`,
			{
				data: {
					title: skillTitle,
				},
			}
		);
	}

	deleteReferentialBlockSkill(skillId) {
		return this.api.processRequest(
			"DELETE",
			`/referentials/skills/${skillId}`
		);
	}

	getReferentialBlockSkillCriterias(skillId) {
		return this.api.processRequest(
			"GET",
			`/referentials/skills/${skillId}`
		);
	}

	updateReferentialBlockSkillCriteria(criteriaId, criteriaDescription) {
		return this.api.processRequest(
			"PUT",
			`/referentials/criterias/${criteriaId}`,
			{
				data: {
					description: criteriaDescription,
				},
			}
		);
	}

	deleteReferentialBlockSkillCriteria(criteriaId) {
		return this.api.processRequest(
			"DELETE",
			`/referentials/criterias/${criteriaId}`
		);
	}

	createReferentialBlockSkillCriteria(skillId, criteriaDescription) {
		return this.api.processRequest(
			"POST",
			`/referentials/skills/${skillId}/criterias`,
			{
				data: {
					description: criteriaDescription,
				},
			}
		);
	}
}
