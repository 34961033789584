import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { t } from "../../Config/i18n";
import { Box } from "../AdminLTE3/Box";
import {
	useAvailableProjectInstancesQuery,
	useAvailableProjectsQuery,
	useProjectsAvailableYearQuery,
} from "../Projects/Queries";
import { FilterSelect } from "../Sauron/FiltersForm";
import useFilters from "../Sauron/useFilters";
import { requestErrorToastManager } from "../Utils/Toasts";
import { getCurrentAcademicYear, nValues } from "../Utils/Utils";
import StepCardHeader from "./StepCardHeader";

export default function Filters({ onFilter }) {
	const currentYear = getCurrentAcademicYear();
	const defaultYearOptions = nValues(2023, currentYear - 2023 + 1)
		.reverse()
		.toFilterOptions();
	const { filters, setFilterOptions, setSelectedOption, setFilterDisabled } =
		useFilters({
			year: {
				label: t("year"),
				type: "select",
				multiple: false,
				clearable: false,
				options: defaultYearOptions,
				default: { label: currentYear, value: currentYear },
			},
			project: {
				label: t("project"),
				type: "select",
				multiple: false,
				clearable: false,
				options: [],
				default: null,
				dependsOn: "year",
			},
			project_instance: {
				label: t("instance"),
				type: "select",
				multiple: false,
				clearable: false,
				disabled: true,
				options: [],
				default: null,
				dependsOn: "project",
			},
		});

	const { data: fetchedYears, errorYears } = useProjectsAvailableYearQuery();

	const {
		data: fetchedProjects,
		error: errorProjects,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useAvailableProjectsQuery(filters.year.selected.value, [
		"B-PRO-100",
		"B-PRO-200",
		"B-PRO-400",
		"B-PRO-510",
	]);
	if (!isFetchingNextPage && hasNextPage) fetchNextPage();

	const { data: fetchedInstances } = useAvailableProjectInstancesQuery(
		filters.year.selected.value,
		filters.project.selected?.value.split("__")[0],
		filters.project.selected?.value.split("__")[1]
	);

	const onSelectChange = (filterKey, newValue) => {
		setSelectedOption(filterKey, newValue);
		if (filterKey === "year") {
			setFilterOptions("project", []);
			onSelectChange("project", null);
			setFilterDisabled("project", true);
		} else if (filterKey === "project") {
			if (newValue === null) {
				setFilterOptions("project_instance", []);
				onSelectChange("project_instance", null);
				setFilterDisabled("project_instance", true);
				return;
			}
			if (filters.project.selected?.value === newValue.value) return;
			filters.project.selected = newValue;
			setFilterDisabled("project_instance", false);
			onSelectChange("project_instance", null);
		} else if (filterKey === "project_instance") {
			setFilterDisabled("project_instance", newValue === null);
			onFilter({
				year: filters.year.selected.value,
				project: filters.project.selected.value,
				project_instance: newValue?.value,
			});
		}
	};

	useEffect(() => {
		if (!fetchedYears) return;
		const yearOptions = fetchedYears.sort().reverse().toFilterOptions();
		setFilterOptions("year", yearOptions);
		setSelectedOption("year", yearOptions[0]);
	}, [fetchedYears]);

	useEffect(() => {
		if (!fetchedProjects || fetchedProjects.length === 0) return;
		const projectOpts = [];

		fetchedProjects.forEach((project) => {
			const label = `[${project.code_module}] ${project.slug}`;
			const value = `${project.code_module}__${project.slug}`;
			projectOpts.push({
				label,
				value,
			});
		});

		projectOpts.sort((a, b) => a.label.localeCompare(b.label));
		setFilterOptions("project", projectOpts);
		setFilterDisabled("project", false);
	}, [fetchedProjects]);

	useEffect(() => {
		if (!fetchedInstances || fetchedInstances.length === 0) return;
		const instancesOpts = [];
		fetchedInstances.forEach((instance) => {
			instancesOpts.push({
				label: instance.code_instance,
				value: instance.code_instance,
			});
		});
		instancesOpts.sort((a, b) => a.label.localeCompare(b.label));
		setFilterOptions("project_instance", instancesOpts);
		setFilterDisabled("project_instance", instancesOpts.length === 0);
	}, [fetchedInstances]);

	requestErrorToastManager(
		"Pro Com Notes: Unable to get available years",
		errorYears
	);
	requestErrorToastManager(
		"Pro Com Notes: Unable to get available projects",
		errorProjects
	);

	return (
		<Box style={{ height: "100%" }}>
			<StepCardHeader
				label={t("Step 1 : select a project").capfirst()}
				success={
					filters.project.selected &&
					filters.project_instance.selected
				}
			/>
			<Card.Body>
				<Row>
					<Col lg={4}>
						<FilterSelect
							name="year"
							filter={filters.year}
							onChange={onSelectChange}
							title={false}
						/>
					</Col>
					<Col lg={4}>
						<FilterSelect
							name="project"
							filter={filters.project}
							onChange={onSelectChange}
							title={false}
						/>
					</Col>
					<Col lg={4}>
						<FilterSelect
							name="project_instance"
							filter={filters.project_instance}
							onChange={onSelectChange}
							title={false}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Box>
	);
}
