import {
	MantineProvider,
	Navbar,
	NavLink,
	ScrollArea,
	Text,
} from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "../../Config/i18n.jsx";
import { SauronRoutes } from "../../Config/Routes.jsx";
import { ScraperApiHelperContext } from "../../contexts.js";
import { hasPermissions } from "../Utils/Utils.jsx";

const PAGES = {
	"full overview": [
		SauronRoutes.dashboard,
		SauronRoutes.students_overview,
		SauronRoutes.cities,
		SauronRoutes.investment_overview,
		SauronRoutes.intuitu_personae,
		SauronRoutes.investment_reports,
	],
	"module validations": [
		SauronRoutes.tesseract,
		SauronRoutes.tesseract_configuration,
		SauronRoutes.evaluations,
		SauronRoutes.cheaters,
		SauronRoutes.professional_communication,
	],
	"alerts overview": [
		SauronRoutes.transcripts,
		SauronRoutes.student_presences,
		SauronRoutes.module_alerts,
		SauronRoutes.tepitech_alerts,
		SauronRoutes.internship_part_time,
		SauronRoutes.diversity_overview,
	],
	"stats": [
		SauronRoutes.module_validations,
		SauronRoutes.tepitech_progress,
		SauronRoutes.internship_progression,
		SauronRoutes.projects,
	],
	"administration": [
		SauronRoutes.scraping_history,
		SauronRoutes.admin_panel,
		SauronRoutes.jwt_manager,
		SauronRoutes.referential_manager,
	],
};

const SauronNavbar = ({ opened }) => {
	const route = location.pathname;
	const navigate = useNavigate();
	const buildPages = (key) => {
		const scraper = useContext(ScraperApiHelperContext);
		const pages = PAGES[key].filter((page) =>
			hasPermissions(page, scraper.api.getSauronUserInfo())
		);
		if (pages.length === 0) {
			return null;
		}
		/*TODO: We have to override the color of the text because of bootstrap*/
		const layouts = [
			<Text fw={700} key={key} mb="xs" mt="xs" color="white">
				{t(key).capfirst()}
			</Text>,
		];
		return layouts.concat(
			pages.map((page) => (
				<NavLink
					label={t(page.label).capfirst()}
					icon={page.icon}
					active={route === page.path}
					variant="filled"
					onClick={() => navigate(page.path)}
					key={`${key}.${page.path}`}
				/>
			))
		);
	};

	return (
		<MantineProvider theme={{ colorScheme: "dark" }}>
			<Navbar
				p="md"
				hiddenBreakpoint="sm"
				hidden={!opened}
				width={{ sm: 200, lg: 250 }}
			>
				<Navbar.Section grow mt="md" component={ScrollArea}>
					{Object.keys(PAGES).map(buildPages)}
				</Navbar.Section>
			</Navbar>
		</MantineProvider>
	);
};

export default SauronNavbar;
