import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n.jsx";

export const useReferentialQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [],
		queryFn: () => scraper.referentials.getReferential(),
		select: (data) => data.data,
	});
};

export const useReferentialNameMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id, name }) =>
			scraper.referentials.updateReferentialName(id, name),
		onError: (error) => {
			toast.error(
				`Referential: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useCreateReferentialMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ name }) => scraper.referentials.createReferential(name),
		onError: (error) => {
			toast.error(
				`Referential: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useDeleteReferentialMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useMutation({
		mutationFn: ({ id }) => scraper.referentials.deleteReferential(id),
		onError: (error) => {
			toast.error(
				`Referential: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useReferentialBlocksQuery = ({ referentialId, enabled }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useQuery({
		queryKey: ["referentialBlocks", referentialId],
		queryFn: () => scraper.referentials.getReferentialBlocks(referentialId),
		select: (data) => data.data.blocks,
		enabled,
	});
};

export const useCreateReferentialBlockMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ referentialId, blockTitle }) =>
			scraper.referentials.createReferentialBlock(
				referentialId,
				blockTitle
			),
		onError: (error) => {
			toast.error(
				`Block: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useUpdateReferentialBlockMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ blockId, blockTitle }) =>
			scraper.referentials.updateReferentialBlock(blockId, blockTitle),
		onError: (error) => {
			toast.error(
				`Block: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useDeleteReferentialBlockMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ blockId }) =>
			scraper.referentials.deleteReferentialBlock(blockId),
		onError: (error) => {
			toast.error(
				`Block: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useCreateReferentialBlockSkillMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ blockId, skillTitle }) =>
			scraper.referentials.createReferentialBlockSkill(
				blockId,
				skillTitle
			),
		onError: (error) => {
			toast.error(
				`Skill: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useDeleteReferentialBlockSkillMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ skillId }) =>
			scraper.referentials.deleteReferentialBlockSkill(skillId),
		onError: (error) => {
			toast.error(
				`Skill: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useUpdateReferentialBlockSkillMutation = ({ onSuccess }) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ skillId, skillTitle }) =>
			scraper.referentials.updateReferentialBlockSkill(
				skillId,
				skillTitle
			),
		onError: (error) => {
			toast.error(
				`Skill: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useGetReferentialBlockSkillCriteriasQuery = ({
	skillId,
	enabled,
}) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useQuery({
		queryKey: ["referentialBlockSkillCriterias", skillId],
		queryFn: () =>
			scraper.referentials.getReferentialBlockSkillCriterias(skillId),
		select: (data) => data.data.criterias,
		enabled,
	});
};

export const useUpdateReferentialBlockSkillCriteriaMutation = ({
	onSuccess,
}) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ criteriaId, criteriaDescription }) =>
			scraper.referentials.updateReferentialBlockSkillCriteria(
				criteriaId,
				criteriaDescription
			),
		onError: (error) => {
			toast.error(
				`Criteria: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useDeleteReferentialBlockSkillCriteriaMutation = ({
	onSuccess,
}) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ criteriaId }) =>
			scraper.referentials.deleteReferentialBlockSkillCriteria(
				criteriaId
			),
		onError: (error) => {
			toast.error(
				`Criteria: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};

export const useCreateReferentialBlockSkillCriteriaMutation = ({
	onSuccess,
}) => {
	const scraper = useContext(ScraperApiHelperContext);

	return useMutation({
		mutationFn: ({ skillId, criteriaDescription }) =>
			scraper.referentials.createReferentialBlockSkillCriteria(
				skillId,
				criteriaDescription
			),
		onError: (error) => {
			toast.error(
				`Referential: ${t("an error occured when saving your edits").capfirst()}: ${error}`,
				{ theme: "colored", autoClose: 7500 }
			);
		},
		onSuccess: onSuccess,
	});
};
