import Cheaters from "../Components/Cheaters/Cheaters";
import Cities from "../Components/Cities/Cities";
import Configurations from "../Components/Configurations/Configurations";
import Dashboard from "../Components/Dashboard/Dashboard";
import Diversity from "../Components/Diversity/Diversity";
import Evaluations from "../Components/Evaluations/Evaluations";
import Home from "../Components/Home/Home";
import InternshipPartTime from "../Components/Internship-PartTime/Internship-PartTime";
import InternshipProgression from "../Components/InternshipProgression/InternshipProgression";
import IntuituPersonae from "../Components/IntuituPersonae/IntuituPersonae";
import InvestmentOverview from "../Components/InvestmentOverview/InvestmentOverview";
import InvestmentReports from "../Components/InvestmentReports/InvestmentReports";
import JwtManager from "../Components/JwtManager/JwtManager";
import ModuleAlerts from "../Components/ModuleAlerts/ModuleAlerts";
import ModuleValidation from "../Components/ModuleValidation/ModuleValidation";
import Logout from "../Components/Oauth2/Logout";
import ProfessionalCommunication from "../Components/ProfessionalCommunication/ProfessionalCommunication";
import Projects from "../Components/Projects/Projects";
import ScrapingHistory from "../Components/ScrapingHistory/ScrapingHistory";
import Student from "../Components/Student/Student";
import StudentPresence from "../Components/StudentPresence/StudentPresence";
import StudentsOverview from "../Components/StudentsOverview/StudentsOverview.jsx";
import TepitechAlerts from "../Components/TepitechAlerts/TepitechAlerts";
import TepitechProgression from "../Components/TepitechProgression/TepitechProgression";
import Tesseract from "../Components/Tesseract/Tesseract";
import TesseractConfiguration from "../Components/TesseractConfiguration/TesseractConfiguration";
import Transcripts from "../Components/Transcripts/Transcripts";

import {
	IconAlertTriangle,
	IconBuildings,
	IconChartBar,
	IconContract,
	IconCube3dSphere,
	IconDashboard,
	IconEdit,
	IconFile,
	IconFilePencil,
	IconInfoCircle,
	IconKey,
	IconLanguageHiragana,
	IconMasksTheater,
	IconPencilMinus,
	IconPuzzle,
	IconSettings,
	IconTimeline,
	IconUserPentagon,
	IconUserQuestion,
	IconUsers,
	IconUserScreen,
	IconUsersGroup,
	IconZoomScan,
	IconFileCertificate,
} from "@tabler/icons-react";
import ReferentialManager from "../Components/ReferentialManager/ReferentialManager.jsx";

export const SauronRoutes = {
	logout: {
		path: "/logout",
		component: Logout,
	},
	home: {
		path: "/",
		component: Home,
	},
	student: {
		path: "/student/:login",
		component: Student,
	},
	dashboard: {
		label: "dashboard",
		path: "/dashboard",
		icon: <IconDashboard />,
		component: Dashboard,
		authorized_groups: ["pedago", "admin"],
	},
	students_overview: {
		label: "students overview",
		path: "/students_overview",
		icon: <IconUsersGroup />,
		component: StudentsOverview,
		authorized_groups: ["pedago", "cei", "admin"],
	},
	cities: {
		label: "city overview",
		path: "/cities",
		icon: <IconBuildings />,
		component: Cities,
		authorized_groups: ["admin", "pedago"],
	},
	investment_reports: {
		label: "investment report",
		path: "/investment_reports",
		icon: <IconFilePencil />,
		component: InvestmentReports,
		authorized_groups: ["admin", "pedago"],
	},
	student_presences: {
		label: "student presences",
		path: "/student_presences",
		icon: <IconUserQuestion />,
		component: StudentPresence,
		authorized_groups: ["pedago", "admin"],
	},
	module_alerts: {
		label: "module alerts",
		path: "/module_alerts",
		icon: <IconAlertTriangle />,
		component: ModuleAlerts,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_alerts: {
		label: "Tepitech alerts",
		path: "/tepitech_alerts",
		icon: <IconLanguageHiragana />,
		component: TepitechAlerts,
		authorized_groups: ["english", "pedago", "admin"],
	},
	internship_part_time: {
		label: "internships and part-time",
		icon: <IconContract />,
		path: "/contracts_overview",
		component: InternshipPartTime,
		authorized_groups: ["pedago", "admin"],
	},
	internship_progression: {
		label: "internships progression",
		icon: <IconTimeline />,
		path: "/internships_progression",
		component: InternshipProgression,
		authorized_groups: ["pedago", "admin"],
	},
	module_validations: {
		icon: <IconChartBar />,
		label: "module validations",
		path: "/module_validations",
		component: ModuleValidation,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_progress: {
		label: "Tepitech progress",
		path: "/tepitech_progress",
		icon: <IconTimeline />,
		component: TepitechProgression,
		authorized_groups: ["english", "pedago", "admin"],
	},
	diversity_overview: {
		label: "Diversity - overview",
		path: "/diversity_overview",
		icon: <IconUserScreen />,
		component: Diversity,
		authorized_groups: ["pedago", "admin"],
	},
	investment_overview: {
		label: "investment overview",
		path: "/investment_overview",
		icon: <IconZoomScan />,
		component: InvestmentOverview,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract: {
		label: "tesseract",
		path: "/tesseract",
		icon: <IconCube3dSphere />,
		component: Tesseract,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract_configuration: {
		label: "tesseract configuration",
		path: "/tesseract_config",
		icon: <IconSettings />,
		component: TesseractConfiguration,
		authorized_groups: ["units_responsible", "admin"],
	},
	evaluations: {
		label: "evaluations",
		path: "/evaluations",
		icon: <IconEdit />,
		component: Evaluations,
		authorized_groups: ["pedago", "admin"],
	},
	intuitu_personae: {
		label: "intuitu_personae",
		path: "/intuitu_personae",
		icon: <IconUsers />,
		component: IntuituPersonae,
		authorized_groups: ["dpr", "admin"],
	},
	transcripts: {
		label: "transcripts overview",
		path: "/transcripts",
		icon: <IconFile />,
		component: Transcripts,
		authorized_groups: ["pedago", "admin"],
	},
	cheaters: {
		label: "cheaters",
		path: "/cheaters",
		icon: <IconMasksTheater />,
		component: Cheaters,
		authorized_groups: ["pedago", "admin"],
	},
	professional_communication: {
		label: "Professional Communication",
		path: "/validations/professional_communication",
		icon: <IconPencilMinus />,
		component: ProfessionalCommunication,
		authorized_groups: ["pedago", "admin"],
	},
	projects: {
		label: "projects contributions",
		path: "/projects",
		icon: <IconPuzzle />,
		component: Projects,
		authorized_groups: ["pedago", "admin"],
	},
	scraping_history: {
		label: "scraping history",
		path: "/scraping_history",
		icon: <IconInfoCircle />,
		component: ScrapingHistory,
		authorized_groups: ["admin"],
	},
	admin_panel: {
		label: "configurations",
		path: "/admin_panel",
		icon: <IconUserPentagon />,
		component: Configurations,
		authorized_groups: ["admin"],
	},
	jwt_manager: {
		label: "JWT manager",
		path: "/jwt_manager",
		icon: <IconKey />,
		component: JwtManager,
		authorized_groups: ["admin"],
	},
	referential_manager: {
		label: "Referential manager",
		path: "/referential_manager",
		icon: <IconFileCertificate />,
		component: ReferentialManager,
		authorized_groups: ["admin"],
	},
};
