export default class UserHelper {
	constructor(api) {
		this.api = api;
	}

	isAdmin() {
		const roles = this.api.getSauronUserInfo().roles || [];
		return roles.includes("admin");
	}

	isInGroup(group) {
		const roles = this.api.getSauronUserInfo().roles || [];
		return roles.includes("admin") || roles.includes(group);
	}

	getCities() {
		const cities = this.api.getSauronUserInfo().cities || [];
		return cities
			.map((x) => ({ value: x.code, label: x.name }))
			.sort((a, b) => a.label.localeCompare(b.label));
	}

	getSupervisedModules() {
		return this.api.getSauronUserInfo().modules_responsible || [];
	}

	accessEuropeanCampus() {
		const cities = this.api.getSauronUserInfo().cities || [];
		return ["BE/BRU", "DE/BER", "ES/BAR"].some((code) =>
			cities.map((city) => city.code).includes(code)
		);
	}
}
