import {
	Section,
	SectionContent,
	SectionHeader,
} from "../AdminLTE3/Section.jsx";
import { t } from "../../Config/i18n.jsx";
import {
	Box,
	Select,
	Paper,
	Text,
	Button,
	Grid,
	Card,
	Menu,
	Group,
	ActionIcon,
	Modal,
	Input,
	Popover,
	Textarea,
} from "@mantine/core";
import { useState, useEffect } from "react";
import {
	IconDots,
	IconPlus,
	IconEdit,
	IconTrash,
	IconCancel,
	IconCheck,
} from "@tabler/icons-react";
import {
	useReferentialQuery,
	useReferentialNameMutation,
	useCreateReferentialMutation,
	useDeleteReferentialMutation,
	useReferentialBlocksQuery,
	useCreateReferentialBlockMutation,
	useUpdateReferentialBlockMutation,
	useDeleteReferentialBlockMutation,
	useCreateReferentialBlockSkillMutation,
	useDeleteReferentialBlockSkillMutation,
	useGetReferentialBlockSkillCriteriasQuery,
	useUpdateReferentialBlockSkillMutation,
	useUpdateReferentialBlockSkillCriteriaMutation,
	useDeleteReferentialBlockSkillCriteriaMutation,
	useCreateReferentialBlockSkillCriteriaMutation,
} from "./Queries.jsx";
import { toast } from "react-toastify";

const ReferentialManager = () => {
	const {
		data: referentials,
		error: referentialsError,
		refetch: refetchReferentials,
	} = useReferentialQuery();
	const [referentialsData, setReferentialsData] = useState([]);
	useEffect(() => {
		if (!referentialsError) return;
		toast.error(
			`Referentials: ${t("An error has occured")}: ${referentialsError.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [referentialsError]);

	useEffect(() => {
		if (!referentials) return;
		setReferentialsData(
			referentials.map((referential) => ({
				value: referential.id.toString(),
				label: referential.title,
			}))
		);
	}, [referentials]);

	const [referentialModalOpened, setReferentialModalOpened] = useState(false);
	const [newReferentialName, setNewReferentialName] = useState("");
	const [editReferentialModalOpened, setEditReferentialModalOpened] =
		useState(false);
	const [editReferentialName, setEditedReferentialName] = useState("");
	const [blockModalOpened, setBlockModalOpened] = useState(false);
	const [editBlockName, setEditBlockName] = useState("");
	const [skillModalOpened, setSkillModalOpened] = useState(false);
	const [editSkillName, setEditSkillName] = useState("");
	const [selectedReferential, setSelectedReferential] = useState(null);
	const [selectedBlock, setSelectedBlock] = useState(null);
	const [selectedSkill, setSelectedSkill] = useState(null);
	const [selectedCriteria, setSelectedCriteria] = useState(null);
	const [isModfyingCriteria, setIsModfyingCriteria] = useState(false);
	const [newBlockModalOpened, setNewBlockModalOpened] = useState(false);
	const [newBlockName, setNewBlockName] = useState("");
	const [newSkillModalOpened, setNewSkillModalOpened] = useState(false);
	const [newSkillName, setNewSkillName] = useState("");
	const [isCreatingCriteria, setIsCreatingCriteria] = useState(false);
	const [newCriteriaDescription, setNewCriteriaDescription] = useState("");
	const [isModifyingSkillName, setIsModifyingSkillName] = useState(false);

	const {
		data: blocks,
		error: blocksError,
		refetch: refetchBlock,
	} = useReferentialBlocksQuery({
		referentialId: selectedReferential?.id,
		enabled: !!selectedReferential,
	});

	const [blocksData, setBlocksData] = useState([]);
	useEffect(() => {
		if (!blocksError) return;
		toast.error(
			`Blocks: ${t("An error has occured")}: ${blocksError.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [blocksError]);

	useEffect(() => {
		if (!blocks) return;
		setBlocksData(blocks);
	}, [blocks]);

	const referentialNameMutate = useReferentialNameMutation({
		onSuccess: () => {
			refetchReferentials();
			refetchBlock();
		},
	});
	const createReferentialMutate = useCreateReferentialMutation({
		onSuccess: () => {
			refetchReferentials();
		},
	});
	const deleteReferentialMutate = useDeleteReferentialMutation({
		onSuccess: () => {
			refetchReferentials();
			setSelectedReferential(null);
		},
	});
	const createBlockMutate = useCreateReferentialBlockMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});
	const updateBlockMutate = useUpdateReferentialBlockMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});
	const deleteBlockMutate = useDeleteReferentialBlockMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});

	const createSkillMutate = useCreateReferentialBlockSkillMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});
	const deleteSkillMutate = useDeleteReferentialBlockSkillMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});
	const updateSkillMutate = useUpdateReferentialBlockSkillMutation({
		onSuccess: () => {
			refetchBlock();
			setBlocksData(blocks);
		},
	});
	const {
		data: criterias,
		error: criteriasError,
		refetch: refetchCriterias,
	} = useGetReferentialBlockSkillCriteriasQuery({
		skillId: selectedSkill?.id,
		enabled: !!selectedSkill,
	});

	const [criteriasData, setCriteriasData] = useState([]);
	useEffect(() => {
		if (!criteriasError) return;
		toast.error(
			`Criterias: ${t("An error has occured")}: ${criteriasError.message}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [criteriasError]);

	useEffect(() => {
		if (!criterias) return;
		setCriteriasData(criterias);
	}, [criterias]);

	const updateSkillCriteriaMutate =
		useUpdateReferentialBlockSkillCriteriaMutation({
			onSuccess: () => {
				refetchCriterias();
				setCriteriasData(criterias);
			},
		});
	const deleteSkillCriteriaMutate =
		useDeleteReferentialBlockSkillCriteriaMutation({
			onSuccess: () => {
				refetchCriterias();
				setCriteriasData(criterias);
			},
		});
	const createSkillCriteriaMutate =
		useCreateReferentialBlockSkillCriteriaMutation({
			onSuccess: () => {
				refetchCriterias();
				setCriteriasData(criterias);
			},
		});

	const handleReferentialChange = (value) => {
		setSelectedReferential(
			referentials.find(
				(referential) => referential.id.toString() === value
			)
		);
	};

	const handleNewReferentialNameChange = (event) => {
		setNewReferentialName(event.target.value);
	};

	const handleReferentialNameEdit = (event) => {
		setEditedReferentialName(event.target.value);
	};

	const handleBlockNameEdit = (event) => {
		setEditBlockName(event.target.value);
	};

	const handleSkillNameEdit = (event) => {
		setEditSkillName(event.target.value);
	};

	return (
		<Section>
			<Modal
				opened={referentialModalOpened}
				onClose={() => setReferentialModalOpened(false)}
				title={t("Create a new referential")}
				centered
			>
				<Input
					placeholder={t("Referential name")}
					onChange={handleNewReferentialNameChange}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "1rem",
					}}
				>
					<Button
						disabled={newReferentialName.length <= 1}
						onClick={() => {
							createReferentialMutate.mutate({
								name: newReferentialName,
							});
							setReferentialModalOpened(false);
						}}
					>
						{t("Add")}
					</Button>
				</Box>
			</Modal>

			<Modal
				opened={blockModalOpened}
				onClose={() => {
					setBlockModalOpened(false);
					setSelectedBlock(null);
				}}
				title={t("Edit the block")}
				centered
			>
				<Input
					placeholder={t("Block name")}
					onChange={handleBlockNameEdit}
					value={editBlockName}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "1rem",
					}}
				>
					<Button
						disabled={editBlockName.length <= 1}
						onClick={() => {
							updateBlockMutate.mutate({
								blockId: selectedBlock?.id,
								blockTitle: editBlockName,
							});
							setBlockModalOpened(false);
						}}
					>
						{t("Save")}
					</Button>
				</Box>
			</Modal>
			<Modal
				opened={skillModalOpened}
				onClose={() => {
					setSkillModalOpened(false);
					setIsModifyingSkillName(false);
					setIsCreatingCriteria(false);
				}}
				title={t("Edit the skill")}
				centered
				size={1200}
			>
				{!isModifyingSkillName ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "1rem",
						}}
					>
						<Text fw={600} fz={20}>
							{editSkillName}
						</Text>
						<Button
							leftIcon={<IconEdit size={"1rem"} />}
							onClick={() => {
								setIsModifyingSkillName(true);
							}}
						>
							{t("Update skill")}
						</Button>
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							gap: "1rem",
							marginBottom: "1rem",
						}}
					>
						<Input
							placeholder={t("Skill name")}
							onChange={handleSkillNameEdit}
							value={editSkillName}
						/>
						<ActionIcon
							color="blue"
							onClick={() => {
								updateSkillMutate.mutate({
									skillId: selectedSkill?.id,
									skillTitle: editSkillName,
								});
								setIsModifyingSkillName(false);
							}}
						>
							<IconCheck />
						</ActionIcon>
						<ActionIcon
							color="red"
							onClick={() => {
								setIsModifyingSkillName(false);
							}}
						>
							<IconCancel />
						</ActionIcon>
					</Box>
				)}
				{criteriasData && criteriasData?.length >= 1 ? (
					<>
						<Text fz={20} fw={600}>
							{t("Criterias :")}
						</Text>
						{criteriasData?.map((criteria) => {
							return (
								<Paper
									shadow="xs"
									p="md"
									sx={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "nowrap",
										marginTop: "1rem",
										justifyContent: "space-between",
									}}
									key={criteria?.id}
								>
									{isModfyingCriteria &&
									selectedCriteria?.id === criteria?.id ? (
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												marginTop: "2rem",
												width: "100%",
												alignItems: "center",
											}}
										>
											<Textarea
												value={
													selectedCriteria?.description
												}
												onChange={(event) =>
													setSelectedCriteria({
														...selectedCriteria,
														description:
															event.target.value,
													})
												}
												minRows={5}
												sx={{ width: "100%" }}
											/>
											<Button
												variant="subtle"
												leftIcon={
													<IconEdit size={"1rem"} />
												}
												onClick={() => {
													updateSkillCriteriaMutate.mutate(
														{
															criteriaId:
																selectedCriteria?.id,
															criteriaDescription:
																selectedCriteria?.description,
														}
													);
													setIsModfyingCriteria(
														false
													);
													setSelectedCriteria(null);
												}}
											>
												{t("Update")}
											</Button>
										</Box>
									) : (
										<>
											<Text>{criteria?.description}</Text>
											<Box
												sx={{
													display: "flex",
													justifyContent: "flex-end",
													gap: "0.5rem",
												}}
											>
												<ActionIcon
													color="blue"
													onClick={() => {
														setIsModfyingCriteria(
															true
														);
														setSelectedCriteria(
															criteria
														);
													}}
												>
													<IconEdit />
												</ActionIcon>
												<ActionIcon
													color="red"
													onClick={() => {
														deleteSkillCriteriaMutate.mutate(
															{
																criteriaId:
																	criteria?.id,
															}
														);
													}}
												>
													<IconTrash />
												</ActionIcon>
											</Box>
										</>
									)}
								</Paper>
							);
						})}
					</>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "1rem",
						}}
					>
						<Text fz={20} fw={700}>
							{t("No criterias found")}
						</Text>
					</Box>
				)}
				{isCreatingCriteria && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "2rem",
							alignItems: "center",
						}}
					>
						<Textarea
							value={newCriteriaDescription}
							onChange={(event) =>
								setNewCriteriaDescription(event.target.value)
							}
							minRows={5}
							sx={{ width: "100%" }}
						/>
						<ActionIcon
							color="blue"
							variant="subtle"
							onClick={() => {
								createSkillCriteriaMutate.mutate({
									skillId: selectedSkill?.id,
									criteriaDescription: newCriteriaDescription,
								});
								setIsCreatingCriteria(false);
								setNewCriteriaDescription("");
							}}
						>
							<IconPlus />
						</ActionIcon>
						<ActionIcon
							color="red"
							variant="subtle"
							onClick={() => {
								setIsCreatingCriteria(false);
								setNewCriteriaDescription("");
							}}
						>
							<IconCancel />
						</ActionIcon>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "1rem",
					}}
				>
					<Button
						color="violet"
						leftIcon={<IconPlus size={"1rem"} />}
						onClick={() => {
							setIsCreatingCriteria(true);
						}}
					>
						{t("Add criteria")}
					</Button>
				</Box>
			</Modal>
			<Modal
				opened={newBlockModalOpened}
				onClose={() => setNewBlockModalOpened(false)}
				title={t("Create a new block")}
				centered
			>
				<Input
					placeholder={t("Block name")}
					onChange={(event) => setNewBlockName(event.target.value)}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "1rem",
					}}
				>
					<Button
						disabled={newBlockName.length <= 1}
						onClick={() => {
							createBlockMutate.mutate({
								referentialId: selectedReferential?.id,
								blockTitle: newBlockName,
							});
							setNewBlockModalOpened(false);
						}}
					>
						{t("Save")}
					</Button>
				</Box>
			</Modal>
			<Modal
				opened={newSkillModalOpened}
				onClose={() => setNewSkillModalOpened(false)}
				title={t("Add a new skill")}
				centered
			>
				<Input
					placeholder={t("Skill name")}
					onChange={(event) => setNewSkillName(event.target.value)}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "1rem",
					}}
				>
					<Button
						disabled={newSkillName.length <= 1}
						onClick={() => {
							createSkillMutate.mutate({
								blockId: selectedBlock?.id,
								skillTitle: newSkillName,
							});
							setNewSkillModalOpened(false);
						}}
					>
						{t("Save")}
					</Button>
				</Box>
			</Modal>

			<SectionHeader title={t("Referential manager").capfirst()} />
			<SectionContent>
				<Box sx={{ backgroundColor: "none" }}>
					<Paper
						shadow="xs"
						p="md"
						withBorder
						sx={{
							display: "flex",
							flexDirection: "column",
							flexWrap: "nowrap",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "nowrap",
								justifyContent: "space-between",
								marginBottom: "1rem",
							}}
						>
							<Text fz="xl">{t("Select a referential")}</Text>
							<Button
								leftIcon={<IconPlus size={"1rem"} />}
								onClick={() => setReferentialModalOpened(true)}
							>
								{t("Add referential")}
							</Button>
						</Box>
						<Select
							data={referentialsData}
							placeholder={t("Research")}
							searchable
							maxDropdownHeight={300}
							nothingFound={t("No referential found")}
							filter={(value, item) =>
								item.label
									.toLowerCase()
									.includes(value.toLowerCase())
							}
							onChange={handleReferentialChange}
						/>
					</Paper>

					{selectedReferential ? (
						<Paper
							shadow="xs"
							p="md"
							withBorder
							sx={{ marginTop: "1rem" }}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "nowrap",
									justifyContent: "space-between",
									marginBottom: "2rem",
								}}
							>
								{!editReferentialModalOpened ? (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "nowrap",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "1rem",
										}}
									>
										<Text fz={25} fw={700}>
											{selectedReferential.title}
										</Text>
										<Text fz={25} fw={400}>
											{t(
												`- Block${blocksData?.length > 1 ? "s" : ""} of competencies`
											)}
										</Text>
									</Box>
								) : (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "nowrap",
											alignItems: "center",
											justifyContent: "space-between",
											gap: "1rem",
										}}
									>
										<Input
											placeholder={t("Referential name")}
											onChange={handleReferentialNameEdit}
											value={editReferentialName}
											size="md"
										/>
										<ActionIcon
											color="blue"
											onClick={() => {
												referentialNameMutate.mutate({
													id:
														selectedReferential?.id ??
														null,
													name: editReferentialName,
												});
												setSelectedReferential({
													...selectedReferential,
													title: editReferentialName,
												});
												setEditReferentialModalOpened(
													false
												);
											}}
										>
											<IconCheck />
										</ActionIcon>
										<ActionIcon
											color="red"
											onClick={() => {
												setEditReferentialModalOpened(
													false
												);
											}}
										>
											<IconCancel />
										</ActionIcon>
									</Box>
								)}
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										gap: "1rem",
									}}
								>
									<Button
										color="violet"
										onClick={() => {
											setNewBlockModalOpened(true);
										}}
										leftIcon={<IconPlus size={"1rem"} />}
									>
										{t("Add a block")}
									</Button>
									<Button
										onClick={() => {
											setEditedReferentialName(
												selectedReferential.title
											);
											setEditReferentialModalOpened(true);
										}}
										leftIcon={<IconEdit size={"1rem"} />}
									>
										{t("Edit")}
									</Button>
									<Popover
										width={250}
										position="bottom-end"
										withArrow
										shadow="md"
									>
										<Popover.Target>
											<Button
												color="red"
												leftIcon={
													<IconTrash size={"1rem"} />
												}
											>
												{t("Delete")}
											</Button>
										</Popover.Target>
										<Popover.Dropdown>
											<Text size="sm">
												{t(
													"Are you sure you want to delete this referential ?"
												)}
											</Text>
											<Box
												sx={{
													display: "flex",
													justifyContent: "flex-end",
													marginTop: "1rem",
												}}
											>
												<Button
													color="red"
													onClick={() => {
														deleteReferentialMutate.mutate(
															{
																id: selectedReferential.id,
															}
														);
													}}
												>
													{t("Confirm")}
												</Button>
											</Box>
										</Popover.Dropdown>
									</Popover>
								</Box>
							</Box>
							{blocksData && blocksData?.length >= 1 ? (
								<Grid
									gutter={5}
									gutterXs="md"
									gutterMd="xl"
									gutterXl={30}
									columns={18}
								>
									{blocksData?.map((block) => {
										return (
											<Grid.Col span={6} key={block.id}>
												<Card
													withBorder
													shadow="md"
													radius="md"
												>
													<Card.Section
														withBorder
														inheritPadding
														py="xs"
													>
														<Group position="apart">
															<Text weight={500}>
																{block.title}
															</Text>
															<Menu
																withinPortal
																position="bottom-end"
																shadow="sm"
															>
																<Menu.Target>
																	<ActionIcon>
																		<IconDots size="1rem" />
																	</ActionIcon>
																</Menu.Target>

																<Menu.Dropdown>
																	<Menu.Item
																		onClick={() => {
																			setSelectedBlock(
																				block
																			);
																			setNewSkillModalOpened(
																				true
																			);
																		}}
																		icon={
																			<IconPlus
																				size={
																					"20"
																				}
																			/>
																		}
																	>
																		{t(
																			"Add a skill"
																		)}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() => {
																			setSelectedBlock(
																				block
																			);
																			setEditBlockName(
																				block.title
																			);
																			setBlockModalOpened(
																				true
																			);
																		}}
																		icon={
																			<IconEdit
																				size={
																					"20"
																				}
																			/>
																		}
																	>
																		{t(
																			"Edit"
																		)}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() => {
																			deleteBlockMutate.mutate(
																				{
																					blockId:
																						block.id,
																				}
																			);
																		}}
																		icon={
																			<IconTrash
																				size={
																					"20"
																				}
																			/>
																		}
																		color="red"
																	>
																		{t(
																			"Delete"
																		)}
																	</Menu.Item>
																</Menu.Dropdown>
															</Menu>
														</Group>
													</Card.Section>
													<Box
														sx={{
															display: "flex",
															flexDirection:
																"column",
															flexWrap: "nowrap",
														}}
													>
														{block?.skills
															?.length >= 1 ? (
															block?.skills?.map(
																(skill) => {
																	return (
																		<Box
																			key={
																				skill.id
																			}
																			sx={{
																				display:
																					"flex",
																				flexDirection:
																					"row",
																				flexWrap:
																					"nowrap",
																				justifyContent:
																					"space-between",
																				marginTop:
																					"1rem",
																			}}
																		>
																			<Text fz="l">
																				{
																					skill.title
																				}
																			</Text>
																			<Box
																				sx={{
																					display:
																						"flex",
																					justifyContent:
																						"space-between",
																				}}
																			>
																				<ActionIcon
																					onClick={() => {
																						setSelectedSkill(
																							skill
																						);
																						setEditSkillName(
																							skill?.title
																						);
																						setSkillModalOpened(
																							true
																						);
																					}}
																					variant="subtle"
																					color="blue"
																				>
																					<IconEdit
																						size={
																							"1.5rem"
																						}
																					/>
																				</ActionIcon>
																				<ActionIcon
																					variant="subtle"
																					color="red"
																					onClick={() => {
																						deleteSkillMutate.mutate(
																							{
																								skillId:
																									skill.id,
																							}
																						);
																					}}
																				>
																					<IconTrash
																						size={
																							"1.5rem"
																						}
																					/>
																				</ActionIcon>
																			</Box>
																		</Box>
																	);
																}
															)
														) : (
															<Box
																sx={{
																	display:
																		"flex",
																	justifyContent:
																		"center",
																	margin: "1rem",
																}}
															>
																<Text
																	fz={20}
																	fw={700}
																>
																	{t(
																		"No skills found"
																	)}
																</Text>
															</Box>
														)}
													</Box>
												</Card>
											</Grid.Col>
										);
									})}
								</Grid>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Text fz={24} fw={700}>
										{t("No blocks found")}
									</Text>
								</Box>
							)}
						</Paper>
					) : null}
				</Box>
			</SectionContent>
		</Section>
	);
};

export default ReferentialManager;
