import {
	Autocomplete,
	Box,
	Burger,
	Flex,
	Group,
	Header,
	LoadingOverlay,
	MantineProvider,
	MediaQuery,
	Menu,
	Switch,
	Text,
	TextInput,
	UnstyledButton,
	rem,
	useMantineColorScheme,
} from "@mantine/core";
import {
	IconArrowBackUp,
	IconChevronDown,
	IconLogout,
	IconMoon,
	IconUser,
	IconZoom,
} from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "../../Config/i18n.jsx";
import { SauronRoutes } from "../../Config/Routes.jsx";
import { ScraperApiHelperContext } from "../../contexts";
import { useSearchStudents } from "../AdminLTE3/Queries.jsx";
import StudentSearchModal from "../AdminLTE3/StudentSearchModal.jsx";
import EpitechAvatar from "../Utils/EpitechAvatar.jsx";
import { getSauronLogo } from "../Utils/Utils.jsx";
import { useGenerateTokenMutation, useUserQuery } from "./Queries.jsx";

const SauronHeader = ({ opened, setOpened }) => {
	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const [submittedSearch, setSubmittedSearch] = useState(null);
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const [, setUserMenuOpened] = useState(false);
	const [students, setStudents] = useState(null);
	const [studentModalOpened, setStudentModalOpened] = useState(false);
	const { data } = useSearchStudents(submittedSearch);
	const logo = getSauronLogo();
	const [callUserQuery, setCallUserQuery] = useState(false);
	const { data: user } = useUserQuery(callUserQuery);
	const [logAsEmail, setLogAsEmail] = useState("");
	const { mutate: generateToken, data: dataToken } =
		useGenerateTokenMutation();
	const scraper = useContext(ScraperApiHelperContext);
	const isAdmin = scraper.userHelper.isAdmin();
	const isLoggedAsSomeone = !!localStorage.getItem(
		"@sauron_previous_account"
	);
	const [isLoggedAsLoading, setIsLoggedAsLoading] = useState(false);

	useEffect(() => {
		if (dataToken) {
			scraper.api.setLogAsSauronToken(dataToken?.data);
			window.location.reload();
		}
	}, [dataToken, scraper]);
	const onSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSwitchOriginalAccount = () => {
		scraper.api.setOriginalSauronAccount();
		window.location.reload();
	};

	const onSearchLogAsChange = (value) => {
		setLogAsEmail(value);
	};

	const handleGenerateToken = (email = null) => {
		setIsLoggedAsLoading(true);
		if (email) {
			generateToken(email);
		} else if (logAsEmail.includes("@epitech.eu")) {
			generateToken(logAsEmail);
		}
	};

	const onSubmit = (event) => {
		if (event.key === "Enter" && search.length > 0) {
			event.preventDefault();
			setSubmittedSearch(search);
			setSearch("");
		}
	};

	useEffect(() => {
		if (data) {
			if (data.results.length === 1)
				navigate(
					SauronRoutes.student.path.replace(
						":login",
						data.results[0].login
					)
				);
			else {
				setStudents(data.results);
				setStudentModalOpened(true);
			}
		}
		setSubmittedSearch(null);
	}, [data]);

	return (
		<MantineProvider theme={{ colorScheme: "dark" }}>
			<Header height={{ base: 70 }} p="md">
				<Flex
					justify="flex-start"
					align="center"
					direction="row"
					wrap="nowrap"
				>
					<MediaQuery smallerThan="sm" styles={{ display: "none" }}>
						<Group
							position="left"
							spacing="md"
							onClick={() => navigate(".")}
							style={{ cursor: "pointer" }}
							sx={{
								width: 230,
								[`@media (max-width: 1199px)`]: {
									width: 180,
								},
							}}
						>
							<Box
								component="img"
								src={logo}
								alt="Sauron Logo"
								sx={() => ({
									height: "38px",
									width: "38px",

									[`@media (max-width: 1000px)`]: {
										height: "26px",
										width: "26px",
									},
								})}
							/>
							<Text fw={700} fz="xl" color="white">
								{" "}
								{/* TODO: We have to override the color of the text because of bootstrap*/}
								Sauron
							</Text>
						</Group>
					</MediaQuery>
					<MediaQuery largerThan="sm" styles={{ display: "none" }}>
						<Burger
							opened={opened}
							onClick={() => setOpened((o) => !o)}
							size="sm"
							mr="sm"
						/>
					</MediaQuery>
					<Flex
						justify="space-between"
						align="center"
						direction="row"
						wrap="nowrap"
						sx={{ flexGrow: 2 }}
					>
						<ScraperApiHelperContext.Consumer>
							{(scraper) => {
								if (
									scraper.api.getSauronUserInfo().roles
										.length === 0
								)
									return null;
								return (
									<TextInput
										placeholder={t(
											"search for a student"
										).capfirst()}
										onChange={onSearchChange}
										value={search}
										onKeyDown={onSubmit}
										radius="md"
										icon={
											<IconZoom
												size="1.5rem"
												stroke={1.5}
											/>
										}
										sx={{
											fontSize: "15px",
											width: "70%",
											[`@media (max-width: 1050px)`]: {
												width: "60%",
											},
											[`@media (max-width: 900px)`]: {
												width: "50%",
											},
											[`@media (max-width: 767px)`]: {
												width: "70%",
											},
										}}
									/>
								);
							}}
						</ScraperApiHelperContext.Consumer>
						<Menu
							onClose={() => setUserMenuOpened(false)}
							onOpen={() => setUserMenuOpened(true)}
							withinPortal
							sx={() => ({
								width: "20rem",
								display: "flex",
								justifyContent: "flex-end",
								[`@media (max-width: 767px)`]: {
									width: "5rem",
								},
								[`@media (max-width: 500px)`]: {
									width: "4rem",
								},
							})}
							position="bottom-end"
						>
							<Menu.Target>
								<UnstyledButton>
									<ScraperApiHelperContext.Consumer>
										{(scraper) => {
											const sauron_user_info =
												scraper.api.getSauronUserInfo();
											return (
												<Group spacing="xs">
													<EpitechAvatar
														login={
															sauron_user_info
																? sauron_user_info.email
																: ""
														}
														name={scraper.api.getUserName()}
													/>
													<MediaQuery
														smallerThan="sm"
														styles={{
															display: "none",
														}}
													>
														<Text fz="sm" fw={700}>
															{scraper.api.getUserName()}
														</Text>
													</MediaQuery>
													<IconChevronDown
														style={{
															width: rem(12),
															height: rem(12),
														}}
														stroke={1.5}
													/>
												</Group>
											);
										}}
									</ScraperApiHelperContext.Consumer>
								</UnstyledButton>
							</Menu.Target>
							<Menu.Dropdown>
								<ScraperApiHelperContext.Consumer>
									{(scraper) => {
										const sauron_user_info =
											scraper.api.getSauronUserInfo();
										return (
											<Menu.Label>
												{sauron_user_info
													? sauron_user_info.email
													: ""}
											</Menu.Label>
										);
									}}
								</ScraperApiHelperContext.Consumer>
								{!isLoggedAsSomeone && isAdmin && (
									<Menu.Item
										icon={<IconUser size={14} />}
										rightSection={
											isLoggedAsLoading ? (
												<LoadingOverlay
													visible={true}
													overlayBlur={2}
												/>
											) : (
												<Autocomplete
													onFocus={() =>
														setCallUserQuery(true)
													}
													placeholder="Se connecter en tant que"
													value={logAsEmail}
													onChange={
														onSearchLogAsChange
													}
													data={user ?? []}
													onItemSubmit={(event) => {
														handleGenerateToken(
															event.value
														);
													}}
													onKeyDown={(event) => {
														if (
															event.key ===
															"Enter"
														)
															handleGenerateToken();
													}}
												/>
											)
										}
										closeMenuOnClick={false}
									></Menu.Item>
								)}
								{isLoggedAsSomeone && (
									<Menu.Item
										onClick={handleSwitchOriginalAccount}
										icon={<IconArrowBackUp size={14} />}
									>
										{t("previousAccount").capfirst()}
									</Menu.Item>
								)}
								<Menu.Item
									icon={<IconMoon size={14} />}
									closeMenuOnClick={false}
									rightSection={
										<Switch
											ml={20}
											checked={colorScheme === "dark"}
											onChange={() => toggleColorScheme()}
										/>
									}
								>
									{t("dark mode")}
								</Menu.Item>
								<Menu.Item
									onClick={() => navigate("logout")}
									color="red"
									icon={<IconLogout size={14} />}
								>
									{t("logout").capfirst()}
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Flex>
					<StudentSearchModal
						students={students}
						opened={studentModalOpened}
						onClose={() => setStudentModalOpened(false)}
					/>
				</Flex>
			</Header>
		</MantineProvider>
	);
};

export default SauronHeader;
